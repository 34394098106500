import { useCallback, useMemo } from "react";
import { RouterLocation } from "types/types";
import { useActiveDocsLink } from "./useActiveDocsLink";
import type { SecondaryLink } from "./useDocsNavLinks";

interface CrossLinks {
  previous: SecondaryLink | null;
  next: SecondaryLink | null;
}

/**
 * For the current page, finds the next and previous tertiary links in
 * a set, from all links across all sections for the current active secondary
 * link.
 *
 * Also adds in quaternary links if specified.
 */
export const useCrossLinkGuides = (
  location: RouterLocation,
  usesQuaternaryLinks: boolean,
): CrossLinks => {
  const {
    secondary: activeSecondaryLink,
    tertiary: activeTertiaryLink,
    quaternary: activeQuaternaryLink,
    currentUrl,
  } = useActiveDocsLink(location);
  const relatedLinks = useMemo(
    () =>
      activeSecondaryLink?.tertiarySections
        // This is to handle the conditionally rendered tertiary sections in supplemental data
        ?.filter((section) => {
          if (!section.pathnameRenderRegex) return true;
          return section.pathnameRenderRegex.test(currentUrl);
        })
        ?.map((section) =>
          section.tertiaryLinks
            .map((tertiaryLink) => {
              // Also add in the 4th level links if necessary
              if (usesQuaternaryLinks) {
                return [tertiaryLink, ...(tertiaryLink.quaternaryLinks ?? [])];
              }
              return tertiaryLink;
            })
            .flat(),
        )
        .flat() ?? [],
    [activeSecondaryLink?.tertiarySections, currentUrl, usesQuaternaryLinks],
  );

  // Grabs a next or previous link - either 3rd or 4th level
  const link = useCallback(
    (activeTertiaryIndex: number, activeQuaternaryIndex: number, multiplier: number) => {
      if (usesQuaternaryLinks && activeQuaternaryIndex > -1) {
        const lastLink = relatedLinks[activeQuaternaryIndex + multiplier];
        if (lastLink?.linkTo === activeQuaternaryLink?.linkTo) {
          return relatedLinks[
            activeQuaternaryIndex + (multiplier < 0 ? multiplier - 1 : multiplier + 1)
          ];
        }
        return lastLink;
      }
      return relatedLinks[activeTertiaryIndex + multiplier];
    },
    [activeQuaternaryLink, relatedLinks, usesQuaternaryLinks],
  );

  return useMemo(() => {
    if (!activeTertiaryLink || !relatedLinks) {
      return {
        previous: null,
        next: null,
      };
    }

    const indexOfActiveLink = relatedLinks.findIndex(
      ({ linkTo }) => linkTo === activeTertiaryLink.linkTo,
    );
    const indexOfActiveQuaternaryLink = activeQuaternaryLink
      ? relatedLinks.findIndex(({ linkTo }) => linkTo === activeQuaternaryLink.linkTo)
      : -1;

    return {
      previous: link(indexOfActiveLink, indexOfActiveQuaternaryLink, -1) ?? null,
      next: link(indexOfActiveLink, indexOfActiveQuaternaryLink, 1) ?? null,
    };
  }, [activeQuaternaryLink, activeTertiaryLink, link, relatedLinks]);
};
