import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Row, Col } from "react-bootstrap";

import HeaderBar from "components/docs/layout/HeaderBar";
import DocumentationSection from "components/docs/DocumentationSection";
import GuidesImgFrameSection from "components/docs/sections/GuidesImgFrameSection";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import { RouterLocation } from "types/types";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import LINKS from "components/docs/navigation/links";

interface Props {
  location: RouterLocation;
}

const IntroductionPage = ({ location }: Props) => {
  const title = "Writing to POST endpoints with Merge";
  const description = "This guide covers how to write to POST endpoints for supported integrations";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <Row>
        <Col>
          <HeaderBar title={title} subtitle={description} />
        </Col>
      </Row>
      <Row className="mt-2 mb-2">
        <Col>
          <DocumentationSection title="Overview">
            <>
              <p>
                Merge&apos;s Unified API allows you to add resources by sending JSON payloads to, or
                &apos;hitting&apos;, post endpoints for supported integrations. This allows you to
                create new employees, candidates, applications and more. In this guide, we&apos;ll
                walk you through writing data via our API. On a Unified API, you can write data to
                multiple integrations while following a common schema. This way, you won&apos;t have
                to deal with varying field names and objects across applications.
              </p>
              <p>
                This example uses <a href="https://www.postman.com/">Postman</a> as our API client,
                but you can also use another client of your choice or use a programming language
                like Python.
              </p>
              <aside className="mt-9">
                <div>
                  For more detailed instructions on using Postman to interact with Merge endpoints,
                  you can refer to <Link to="/guides/testing-via-postman">the Postman guide</Link>{" "}
                  in our Docs.
                </div>
              </aside>
              <p className="mt-9">
                Before you get started, you&apos;ll want to make sure the integration you are
                working with has support for POST requests. You can see a comprehensive overview of
                the supported actions per common model by sending a GET request to the{" "}
                <Link to={LINKS.LINKED_ACCOUNTS.linkTo}>Linked Accounts endpoint</Link> (with the
                relevant linked account token included in the headers as{" "}
                <code>X-Account-Token</code>).
              </p>
              <p>
                The keyword to look out for here is &quot;CREATE&quot;, under the{" "}
                <code>available_model_operations</code> field. In this example, we are checking the
                available actions for an ATS integration that we want to create candidates on:
              </p>
              <GuidesImgFrameSection>
                <StaticImage
                  src="../../assets/images/docs/guides/writing-post-new/available-actions.png"
                  alt="API Keys preview"
                  placeholder="none"
                  layout="constrained"
                  width={660}
                  height={500}
                />
              </GuidesImgFrameSection>
            </>
          </DocumentationSection>
        </Col>
      </Row>
      <hr className="mt-6 mb-9" />
      <Row className="mt-2 mb-2">
        <section id="json-payloads">
          <Col xs={12} sm={12} md={12} lg={10} xl={8}>
            <DocumentationSection title="Including JSON Payloads in the Request Body">
              <>
                <p>
                  The main difference between POST endpoints and GET endpoints is that POST requests
                  typically include JSON payloads in the request body. These contain the data that
                  will be sent to the server to create a resource.
                </p>
                <p>
                  Create a response body under the <b>Body</b> tab of Postman. Select <b>raw</b> and
                  choose <b>JSON</b> as the data type from the dropdown menu to the right. Note that
                  our APIs only accept JSON data, and form data is not accepted. All common model
                  data should be nested inside the <b>model</b> parameter.
                </p>
                <GuidesImgFrameSection>
                  <StaticImage
                    src="../../assets/images/docs/guides/writing-post-new/post-candidate.png"
                    alt="API Keys preview"
                    placeholder="none"
                    layout="constrained"
                    width={889}
                  />
                </GuidesImgFrameSection>
                <p>
                  <b>Note:</b> Our APIs only accept data in valid JSON form.
                </p>
                <p>
                  Often, you&apos;ll need to pass in valid Merge IDs into request bodies for the
                  POST requests to be valid. If you need to retrieve available IDs, use GET requests
                  to look at data in the server.
                </p>
                <p>
                  Be sure to pay close attention to data types; some fields are entered differently
                  based on the category. For example, emails for the POST <code>/employees</code>{" "}
                  endpoint (HRIS) are passed through in string fields called{" "}
                  <code>personal_email</code> and <code>work_email</code>. The POST{" "}
                  <code>/candidates </code>
                  endpoint in the ATS category requires emails to be passed in as an array, as
                  shown:
                </p>
                <GuidesImgFrameSection>
                  <StaticImage
                    src="../../assets/images/docs/guides/writing-post-new/email-addresses.png"
                    alt="API Keys preview"
                    placeholder="none"
                    layout="constrained"
                    width={660}
                  />
                </GuidesImgFrameSection>
                <GuidesImgFrameSection>
                  <StaticImage
                    src="../../assets/images/docs/guides/writing-post-new/email-addresses-format.png"
                    alt="API Keys preview"
                    placeholder="none"
                    layout="constrained"
                    width={660}
                  />
                </GuidesImgFrameSection>
              </>
            </DocumentationSection>
          </Col>
        </section>
      </Row>
      <hr className="my-9" />
      <Row className="mb-9">
        <section id="handling-permissions">
          <Col>
            <DocumentationSection title="Handling Permissions with Remote User IDs">
              <>
                <p>
                  Another area often required for writes is <b>permissions</b>. Many APIs have
                  restrictions around who can write data, and look out for specific user roles when
                  processing POST requests. This is the case for several of our ATS integrations
                  right now, which require users to have specific permissions for creating
                  candidates and posting them to open jobs.
                </p>
                <p>
                  Permissions are handled by Merge&apos;s <code>remote_user_id</code> field, which
                  is passed through as a separate, top-level body parameter. The field indicates the
                  ID of the user submitting the request and allows the server to verify the
                  user&apos;s access role. To verify if this parameter is required for a given
                  integration is to use the{" "}
                  <Link to={LINKS.LINKED_ACCOUNTS.linkTo}>Linked Accounts endpoint</Link>. Look for
                  the <code>required_post_parameters</code> array, as shown in this example for
                  creating candidates on an ATS integration:
                </p>
                <GuidesImgFrameSection>
                  <StaticImage
                    src="../../assets/images/docs/guides/writing-post-new/required-post-parameters.png"
                    alt="API Keys preview"
                    placeholder="none"
                    layout="constrained"
                    width={660}
                  />
                </GuidesImgFrameSection>
              </>
            </DocumentationSection>
          </Col>
        </section>
      </Row>
      <hr className="my-9" />
      <Row className="mb-9">
        <section id="including-enum">
          <Col>
            <DocumentationSection title="Including enum Values">
              <>
                <p className="mt-3">
                  An <code>enum</code>, or an enumerated type, is a data type consisting of named
                  values. <code>Enums</code> help to normalize values from different APIs by mapping
                  them to Merge-specific values.
                </p>
                <p className="mt-3">
                  When writing to a POST endpoint with an enum field that you choose to use, you
                  will have to pass in one of the listed values for that field. For example, the
                  POST <code>/Employees</code> endpoint in HRIS includes optional fields for{" "}
                  <code>ethnicity</code>, <code>marital_status</code>, <code>gender</code> and{" "}
                  <code>employment_status</code>. If you are creating an employee with one or more
                  of these fields, follow the syntax provided to pass in the appropriate values.
                </p>
                <GuidesImgFrameSection>
                  <StaticImage
                    src="../../assets/images/docs/guides/writing-post-new/enum-examples.png"
                    alt="API Keys preview"
                    placeholder="none"
                    layout="constrained"
                    width={660}
                  />
                </GuidesImgFrameSection>
              </>
            </DocumentationSection>
            <CrossGuideLinks location={location} style={{ marginTop: "40px" }} />
          </Col>
        </section>
      </Row>
      <hr className="my-9" />
      <Row className="mb-9">
        <section id="sending-post-requests">
          <Col xs={12} sm={12} md={12} lg={10} xl={8}>
            <DocumentationSection title="Sending POST Requests">
              <>
                <p className="p-3">
                  Construct the payload with the data you want to write to the server, and click{" "}
                  <b>Send</b>:
                </p>
                <GuidesImgFrameSection>
                  <StaticImage
                    src="../../assets/images/docs/guides/writing-post-new/sending-post-send-button.png"
                    alt="API Keys preview"
                    placeholder="none"
                    layout="constrained"
                    width={220}
                  />
                </GuidesImgFrameSection>
                <p className="p-3">
                  A response body is returned after a successful request, along with a{" "}
                  <code>201</code> status code (indicating that a resource has been created):
                </p>
                <GuidesImgFrameSection>
                  <StaticImage
                    src="../../assets/images/docs/guides/writing-post-new/201-Created.png"
                    alt="API Keys preview"
                    placeholder="none"
                    layout="constrained"
                    width={660}
                  />
                </GuidesImgFrameSection>
              </>
            </DocumentationSection>
          </Col>
        </section>
      </Row>
      <hr className="my-9" />
      <Row className="mb-9">
        <section id="required-fields">
          <Col xs={12} sm={12} md={12} lg={10} xl={8}>
            <DocumentationSection title="Required Fields">
              <>
                <p>
                  Many integrations will have required fields for writing to certain endpoints. If
                  these fields are missing from the payload, the POST request will not go through.
                  An error message will notify you if you are missing a required field for that
                  integration.
                </p>
                <p>
                  In this example, the error message informs us that we&apos;ve missed{" "}
                  <code>email_addresses</code>, a required field for posting candidates to this
                  application:
                </p>
                <GuidesImgFrameSection>
                  <StaticImage
                    src="../../assets/images/docs/guides/writing-post-new/Required-Fields.png"
                    alt="Required Fields Error"
                    placeholder="none"
                    layout="constrained"
                    width={660}
                  />
                </GuidesImgFrameSection>
                <p>
                  By now, you should be ready to create candidates, employees and more. Have further
                  questions about using our POST endpoints? Contact us at hello@merge.dev to resolve
                  your doubts.
                </p>
              </>
            </DocumentationSection>
          </Col>
        </section>
      </Row>
    </StaticPageContainer>
  );
};

export default IntroductionPage;
