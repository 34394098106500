import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

const ClearImgFrame = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 0.5rem;
  box-shadow: none;
  position: relative;
  overflow: hidden;
`;

interface Props {
  children?: JSX.Element;
}

const GuidesImgFrameSection = ({ children }: Props) => (
  <Row>
    <Col xs="12" className="mx-auto">
      <ClearImgFrame className="my-9 p-9">{children}</ClearImgFrame>
    </Col>
  </Row>
);

export default GuidesImgFrameSection;
