import DeprecatedH2 from "components/deprecated/DeprecatedH2";
import React from "react";
import { Col, Row } from "react-bootstrap";

type Props = Pick<React.ComponentProps<"div">, "children" | "className"> & {
  title: string;
};

const DocumentationSection = ({ title, children, className }: Props) => (
  <Row className={className}>
    <Col>
      <DeprecatedH2 className="mb-3">{title}</DeprecatedH2>
      {children}
    </Col>
  </Row>
);

export default DocumentationSection;
