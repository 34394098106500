import React from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { spectrum } from "styles/theme";

// Background + padding, mostly
const CODE_STYLE = css`
  font-family: var(--font-family-monospace);
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  background: ${spectrum.gray0};
  padding: 1px 5px;
  border-radius: 3px;
`;

const PaddedRow = styled(Row)<{ lessPadding?: boolean }>`
  && {
    @media (max-width: 767.96px) {
      padding-top: 4px;
      padding-left: 4px;
      padding-right: 4px;
    }
    padding: 40px 40px ${(props) => (props.lessPadding ? "29px" : "416px")} 40px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    overflow: hidden;

    code {
      ${CODE_STYLE};
    }
  }
`;

interface Props {
  children: JSX.Element | JSX.Element[];
  style?: object;
  lessPadding?: boolean;
}

/**
 * Container for article-style pages like "Getting Started", etc.
 */
export const StaticPageContainer = ({ children, style, lessPadding = false }: Props) => (
  <PaddedRow className="justify-content-center" style={style} lessPadding={lessPadding}>
    <Col className="col-12 col-lg-10 col-xl-8 p-0">{children}</Col>
  </PaddedRow>
);
