import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import ChevronLeftSvg from "../../../assets/icons/chevron-left.svg";
import ChevronRightSvg from "../../../assets/icons/chevron-right.svg";
import { RouterLocation } from "../../../types/types";
import { useCrossLinkGuides } from "./useCrossLinkGuides";

interface Props {
  location: RouterLocation;

  /**
   * True if the quaternary links are also used as next/previous. Defaults
   * to false.
   */
  usesQuaternaryLinks?: boolean;
}

const Container = styled.div`
  padding: 0;
  width: 100%;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
`;

export const FlexRow = styled.div`
  @media (max-width: 767.96px) {
    padding: 40px 0;
  }
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px 0;
  border-top: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-top: 0.5px solid var(--gray20);
  }
`;

export const Left = styled.div`
  text-align: left;
`;

export const Right = styled.div`
  text-align: right;
`;

export const LinkTitle = styled.h6`
  &&& {
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #6e84a3;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
`;

export const CrossGuideLink = styled(Link)`
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #1a57f6;
`;

export const ChevronRight = styled(ChevronRightSvg)`
  margin: 0 2px;
  width: 11px;
  height: 23px;
`;

export const ChevronLeft = styled(ChevronLeftSvg)`
  margin: 0 2px;
  width: 11px;
  height: 23px;
`;

/**
 * For steps in a guide/links that are in a collection in docs (say, Merge API Basics under API Reference),
 * we show next and previous links at the bottom of the page. This renders those links if they're existent.
 */
const CrossGuideLinks = ({
  location,
  usesQuaternaryLinks = false,
  className,
  style,
}: Props & Pick<React.ComponentProps<"div">, "className" | "style">) => {
  const { previous, next } = useCrossLinkGuides(location, usesQuaternaryLinks);
  return (
    (previous || next) && (
      <Container className={className} style={style}>
        <FlexRow>
          <Left>
            {previous && (
              <>
                <LinkTitle>Previous</LinkTitle>
                <CrossGuideLink to={previous.linkTo}>
                  <ChevronLeft />
                  {previous.crossLinksText ?? previous.text}
                </CrossGuideLink>
              </>
            )}
          </Left>
          <Right>
            {next && (
              <>
                <LinkTitle>Next Up</LinkTitle>
                <CrossGuideLink to={next.linkTo}>
                  {next.crossLinksText ?? next.text}
                  <ChevronRight />
                </CrossGuideLink>
              </>
            )}
          </Right>
        </FlexRow>
      </Container>
    )
  );
};

export default CrossGuideLinks;
