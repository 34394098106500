import { Badge } from "@merge-api/merge-javascript-shared";
import DeprecatedH6 from "components/deprecated/DeprecatedH6";
import React from "react";
import styled, { css } from "styled-components";

/**
 * "top-level": No "Merge Docs" pre-header, larger title and sub-title. Some spacing between subtitle and border
 *
 * "normal": "Merge Docs" pre-header
 *
 * "expanded-spacing": No "Merge Docs" pre-header, expanded spacing between title and subtitle and between subtitle and border
 *
 */
type Styling = "top-level" | "normal";

interface Props {
  preTitle?: string;
  title: string | React.ReactElement;

  /**
   * Usually a string, can be a fragment
   */
  subtitle?: string | React.ReactElement;

  /**
   * Defaults to normal spacing, but can be expanded to add
   * more spacing between text and border
   */
  styling?: Styling;

  /**
   * Show divider below the header section.
   */
  showDivider?: boolean;

  className?: string;
  children?: React.ReactNode;
  isBeta?: boolean;
}

const Header = styled.div`
  font-family: var(--font-family-sans-serif);
  padding: 24px 0px 36px;
`;

const BorderDiv = styled.div<{ $showDivider: boolean }>`
  ${({ $showDivider }) =>
    $showDivider &&
    css`
      border-bottom: 1px solid var(--gray10);

      /* Retina */
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        border-bottom: 0.5px solid var(--gray20);
      }
    `};
`;

const Body = styled.div`
  padding-bottom: 36px;
`;

const Title = styled.h1.attrs({ className: "header-title" })<{ $largeText: boolean }>`
  display: flex;
  align-items: center;
  && {
    font-size: ${({ $largeText }) => ($largeText ? "36px" : "28px")};
    line-height: 36px;
  }
`;

const Subtitle = styled.div.attrs({ className: "mt-3" })<{
  $largeText: boolean;
  $addSpace: boolean;
}>`
  && {
    ${({ $largeText }) =>
      $largeText
        ? css`
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
          `
        : css`
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
          `}
    ${({ $addSpace }) =>
      $addSpace &&
      css`
        && {
          margin-top: 16px !important;
          margin-bottom: 0;
        }
      `}
  }
`;

const HeaderBar = ({
  preTitle,
  title,
  subtitle,
  styling = "normal",
  showDivider = true,
  className,
  children,
  isBeta,
}: Props) => (
  <Header className={className}>
    <BorderDiv $showDivider={showDivider}>
      <Body>
        {styling === "normal" && preTitle && (
          <DeprecatedH6 className="header-pretitle">{preTitle}</DeprecatedH6>
        )}
        <Title $largeText={styling === "top-level"}>
          {title}
          {isBeta && (
            <Badge className="ml-3" size="lg" color="blue">
              Beta
            </Badge>
          )}
        </Title>
        {subtitle && (
          <Subtitle $largeText={styling === "top-level"} $addSpace={styling === "top-level"}>
            {subtitle}
          </Subtitle>
        )}
      </Body>
      {children}
    </BorderDiv>
  </Header>
);

export default HeaderBar;
