import React from "react";

interface DeprecatedH2Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const DeprecatedH2: React.FC<DeprecatedH2Props> = ({ className, children, style }) => {
  return (
    <div className={`deprecatedh2 ${className}`} style={style}>
      {children}
    </div>
  );
};

export default DeprecatedH2;
